import React from "react"
import presets from "../../utils/presets"
import CloseIcon from "../../images/close.png"

class VideoPlay extends React.Component {

  state = {
    videoPlay: false
  }

  playVideo = () => {
    this.videoRef.load();
    this.videoRef.controls = true;
    this.setState({videoPlay: true});
    this.videoRef.play();
    this.modalRef.style.display = "block";
  }

  closeVideo = (e) => {
    this.modalRef.style.display = "none";
    this.videoRef.pause();
    this.setState({videoPlay: false});
  }

  render() {
    const { videoProps } = this.props;
    return (
      <div css={{ marginBottom: 15 }}>
        <div css={{
          display: 'none',
          [presets.Tablet]: {
            display: 'block'
          }
        }}>
          <video ref={node => this.videoRef = node} id="video" width="90%" poster={videoProps.videoImg.publicURL} controls={true} preload="auto">
            <source type="video/mp4" src={videoProps.videoUrl.publicURL} />
          </video>
        </div>
        <div css={{
          display: 'block',
          [presets.Tablet]: {
            display: 'none'
          }
        }}>
          <a onClick={this.playVideo} css={{
            display: 'block',
            width: '100%',
            margin: '0 auto'
          }}>
            <img src={videoProps.videoImg1.publicURL} alt="" />
          </a>
          <div css={{ display: this.state.videoPlay ? 'block' : 'none' }}>
          </div>
          <div ref={node => this.modalRef = node} className="modal" id="modal" css={styles.modal} onClick={this.closeVideo}>
            <a href="javascript:void(0);" css={styles.close} onClick={this.closeVideo}>
              <img src={CloseIcon} alt="" />
            </a>
            <div className="con" css={{
              display: 'inline-block',
              width: '100%',
              verticalAlign: 'middle',
              textAlign: 'center'
            }}>
                <video ref={node => this.videoRef = node} css={styles.video} id="video" width="98%" poster="" controls preload="auto">
                  <source type="video/mp4" src={videoProps.videoUrl.publicURL} />
                </video>
            </div>
          </div>
        </div>
        { videoProps.videoTitle ? <center css={{ marginTop: 15 }}>{videoProps.videoTitle}</center> : null }
      </div>
    )
  }
}

const styles = {
    modal: {
      display: 'none',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 500,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      textAlign: 'center',
      fontSize: 0,
    },
    close: {
      display: 'block',
      width: '50px',
      height: '50px',
      position: 'absolute',
      right: '20px',
      top: '20px',
      cursor: 'pointer',
      zIndex: 400,
    },
    video: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
}

export default VideoPlay
