import React from "react"
import { StaticQuery, graphql } from "gatsby"
import presets from "../utils/presets"
import Button from "../components/button"

export default (props) => {
  return (
    <div>
      <StaticQuery
        query={query}
        render={data => {
          const { edges: newsList } = data.allMarkdownRemark
          const _index = newsList.findIndex(({ node }) => node.id === props.id)
          const _prevDisable = _index > 0 ? false : true, _nextDisable = _index < newsList.length - 1 ? false : true
          const disableCss = { color: '#aaa', borderColor: '#aaa' }
          return (
          <div css={styles.sticky}>
            <div css={styles.pageDiv}>
              <Button small to="/about/news/" overrideCSS={{
                marginTop: `0rem`,
                marginLeft: `0rem`,
                textDecoration: 'none',
                fontSize: '0.7937rem',
                [presets.Tablet]: {
                  fontSize: 14
                }
              }}>
                  返回列表页
              </Button>
              <div css={{ marginBottom: 15, float: 'right' }}>
                <Button small secondary 
                  disabled={_prevDisable} 
                  overrideCSS={{
                    marginRight: 15,
                    [presets.Tablet]: {
                      fontSize: 14
                    }
                }} 
                  style={_prevDisable ? disableCss : {}} 
                  to={newsList[_index - 1] ? newsList[_index - 1].node.fields.slug : '#'}
                >
                  上一条
                </Button>
                <Button small secondary 
                  disabled={_nextDisable} 
                  overrideCSS={{
                    [presets.Tablet]: {
                      fontSize: 14
                    }
                  }} 
                  style={_nextDisable ? disableCss : {}} 
                  to={newsList[_index + 1] ? newsList[_index + 1].node.fields.slug : '#'}
                >
                  下一条
                </Button>
              </div>
            </div>
          </div>
          )
        }}
      />
      {props.children}
    </div>
  )
}

const styles = {
  pageDiv: {
    marginBottom: 15,
    paddingTop: 15,
    marginTop: -24,
    [presets.Tablet]: {
      marginTop: -15,
    },
  },
  sticky: {
    background: `white`,
    borderBottom: `1px solid ${`white`}`,
    position: `sticky`,
    top: `calc(${presets.headerHeight} - 2px)`,
    zIndex: 1,
    [presets.Tablet]: {
      top: ``,
      zIndex: 0,
    },
  },
}

const query = graphql`
  query QueryNewsList {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "about-news"} } } sort: { fields: [frontmatter___date], order: DESC }) {
        totalCount
        edges {
            node {
                id
                frontmatter {
                    title
                }
                fields {
                    slug
                }
            }
        }
    }
  }
`